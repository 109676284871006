import {sortUtils} from './sortUtils';

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);  // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
  ;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // Distance in km
  return R * c;
};

export const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

export const getFullAddress = address => {

  const {street, postalCode, city} = address;

  return `${street}, ${postalCode}, ${city}`;

};

export const getStrapiLocale = (localeString) => {

  if (localeString.toLowerCase().includes('de'))
    return 'de-DE';

  return localeString;

};

export const groupPartnerWorkshopsInCountries = (partners) => {

  const _countries = {};
  for (const partner of partners) {

    const {address} = partner;
    const {country} = address;
    const {code: countryCode, text} = country;

    const key = countryCode.toLowerCase();

    if (key in _countries) {
      _countries[key].partners.push(partner);
    } else {
      _countries[key] = {
        key: key.toUpperCase(),
        text,
        partners: [partner],
      };

    }

  }

  for (const country of Object.values(_countries)) {

    country.partners = country.partners.sort((a, b) => {

      const {address: addressA} = a;
      const {address: addressB} = b;

      return sortUtils('postalCode', addressA, addressB);

    });

  }

  return _countries;
};
