import Image from 'next/image';
import Link from 'next/link';
import {logoPlainBlackBg} from '../../lib/utils/logo';
import {getAddress} from '../../lib/utils/checkoutUtils';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';

export default function WorkshopMapPin({
                                         partner,
                                         showLink,
                                         isCheckout,
                                         setPartner,
                                         selectedPartnerId,
                                       }) {
  const {t: translateCommon} = useTranslation('common');

  const selectPartner = (e, partnerId) => {
    e.preventDefault();
    setPartner(partnerId);
  };

  const {url, height, width, alternativeText} = logoPlainBlackBg;
  const {street, postalCode, city} = partner.address;
  const {locale} = useRouter();

  const fullAddress = getAddress(street, postalCode, city);

  return <div className={'relative dropdown'}>
    <div className={`transition-all transition ${selectedPartnerId !== null &&
    selectedPartnerId === partner.id ?
        'z-30 w-8 w-8 animate-bounce' :
        'w-8 h-8 opacity-90'}`}>
      <Image src={url} width={width} height={height} alt={alternativeText}
             loading={'lazy'}
             layout={'responsive'} className={`rounded-full`}/>
    </div>
    <div
        className={'transition max-w-lg bg-gray-100 w-52 px-5 py-5 rounded relative right-20 top-0 dropdown-content z-30'}>
      <div className={'flex flex-col'}>
        <h3 className={'text-gray-700 font-medium font-body'}>{partner.title}</h3>
        <p className={'text-gray-500 mb-3 font-body'}>{fullAddress}</p>
        {!!isCheckout ?
            <div className={''}>
              <button onClick={(e) => selectPartner(e, partner.id)}
                      className={'font-body font-bold flex flex-row items-center w-full text-blue-700 transition hover:text-gray-800'}>
                <svg className="hover:text-red-400 transition h-4 w-4 mr-2"
                     fill="none" stroke="currentColor"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth={2} d="M9 5l7 7-7 7"/>
                </svg>
                {translateCommon(
                    'BTN.CHOOSE')}
              </button>
            </div> :
            <></>}
        {showLink ? <Link href={`/${locale}/partners/${partner.slug}`}>
          <a>
            <span
                className={'text-blue-700 font-body font-bold'}>{translateCommon(
                'MORE_ABOUT')} </span>
          </a>
        </Link> : <></>}
      </div>
    </div>
  </div>;

}
