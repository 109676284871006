export const sortUtils = (key, a, b, asc = true) => {

  if (a[key] > b[key])
    return asc ? 1 : -1;

  if (a[key] < b[key])
    return asc ? -1 : 1;

  return 0;

};

export const sortByTime = (key,a,b, asc = true) => {

  const dateA = new Date(a[key]).getTime();
  const dateB = new Date(b[key]).getTime();

  if (dateA > dateB)
    return asc ? 1 : -1;

  if (dateA < dateB)
    return asc ? -1 : 1;

  return 0;

}