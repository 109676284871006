import WorkshopMapPin from './workshopMapPin';
import GoogleMapReact from 'google-map-react';
import React, {useEffect, useState} from 'react';
import qs from 'qs';

import {
  geocodingOnPostalCode,
} from '../../lib/api/rest/locationiq';
import {getDistanceFromLatLonInKm} from '../../lib/utils/locationUtils';
import ContainedButton from '../buttons/containedButton';
import {lightGoogleMapsStyles} from '../../public/googleMapStyles/light';
import {useTranslation} from 'next-i18next';
import {getAddress} from '../../lib/utils/checkoutUtils';
import {findAsync} from '../../lib/api/rest/strapi';
import {paths} from '../../lib/api/rest/paths';
import _ from 'lodash';
import {sortUtils} from '../../lib/utils/sortUtils';

export default function PartnerMap({
                                     options,
                                     showSearchForm,
                                     partners,
                                     showPartnerLink,
                                     mapHeight = 'h-128',
                                     isCheckout,
                                     setPartner,
                                     selectedPartnerId,
                                     showNearestPartner = false,
                                   }) {

  const {t: translateCommon} = useTranslation('common');
  const [workshops, setWorkshops] = useState(partners);
  const [countries, setCountries] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {

    const query = {_limit: 100};
    const queryString = qs.stringify(query);

    findAsync(paths.COUNTRIES, false, queryString).then(countries => {
          setCountries(countries);
          const defaultCountry = countries.find(
              ({code}) => code.toLowerCase() === 'de'); //TODO: Set this based on language
          setSelectedCountry(defaultCountry);
        },
    );

  }, []);

  useEffect(() => {

    const _partners = partners.filter(
        ({isPublic}) => !!isPublic ? isPublic : false).
        sort((a, b) => {

          const {address: addressA} = a;
          const {address: addressB} = b;

          return sortUtils('postalCode', addressA, addressB);

        });

    setWorkshops(_partners);

  }, [partners]);

  const GOOGLE_MAPS_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY;

  const shippingCountries = [
    {
      value: 5,
      text: '5',
    }, {
      value: 10,
      text: '10',
    }, {
      value: 15,
      text: '15',
    },
  ];

  const [formData, setFormData] = useState({
    postalCode: '',
    limiter: 5,
  });

  const [error, setError] = useState('');

  const handleSearch = async (e) => {
    e.preventDefault();

    const {postalCode, limiter} = formData;
    if (!!!postalCode || postalCode.length < 4) {

      setError(translateCommon('FORMS.ERROR.POSTCODE_LENGTH_ABOVE_4'));

      return;
    }

    setError('');

    geocodingOnPostalCode(postalCode, selectedCountry.code, (error) => {
    }).then(response => {

      console.log(response);

      const myLocation = response[0];
      const lat = parseFloat(myLocation.lat);
      const lon = parseFloat(myLocation.lon);

      const _workshops = workshops.map(workshop => {
        workshop.distance = getDistanceFromLatLonInKm(lat, lon,
            workshop.latitude, workshop.longitude);

        return workshop;
      });

      return _workshops.sort(function compare(a, b) {

        if (a.distance > b.distance)
          return 1;

        if (a.distance < b.distance)
          return -1;

        return 0;
      });

    }).then(sortedWorkshops => {

      setWorkshops(sortedWorkshops.slice(0, limiter));

    });
  };

  const handleChange = (event) => {

    event.preventDefault();

    const {name, value} = event.target;

    const _formData = {
      ...formData,
    };

    _formData[name] = value;
    setFormData(_formData);

  };
  const handleChangeCountry = (event) => {

    event.preventDefault();

    const {name, value} = event.target;

    const country = countries.find(({code}) => value === code);
    setSelectedCountry(country);
  };

  return <>
    {showSearchForm ?
        <div>
          <p className={'text-gray-900 mb-5'}>{translateCommon(
              'FORMS.FILL_YOUR_POSTCODE')}</p>
          <form>
            <div className={'grid grid-cols-5 gap-3'}>
              {/* Search form */}

              <input
                  className={`appearance-none rounded col-span-2 md:col-span-1 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-700 focus:border-4 border h-12 transition text-sm ${error.length >
                  0 ? 'border-red-500' : ''}`}
                  id="postalCode"
                  name="postalCode"
                  value={formData.postalCode}
                  type="number"
                  onChange={(e) => handleChange(e)}
                  placeholder={`${translateCommon('USER.POSTCODE')}*`}/>

              <select
                  className="rounded py-2 px-3 text-gray-700 col-span-1 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-700 focus:border-4 border h-12 transition text-sm cursor-pointer"
                  id="limiter"
                  name="limiter"
                  value={formData.limiter}
                  onChange={(e) => handleChange(e)}
              >
                {shippingCountries.map(country => <option key={country.value}
                                                          value={country.value}>{country.text}</option>)}
              </select>
              {<select
                  className="rounded py-2 px-3 text-gray-700 col-span-2 md:col-span-1 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-700 focus:border-4 border h-12 transition text-sm cursor-pointer"
                  id="limiter"
                  name="limiter"
                  value={!!selectedCountry && !!selectedCountry.code ?
                      selectedCountry.code :
                      null}
                  onChange={(e) => handleChangeCountry(e)}
              >
                {!!countries &&
                countries.map(country => <option key={country.code}
                                                 value={country.code}>{country.text}</option>)}
              </select>}
              <ContainedButton onClick={handleSearch}
                               className={'col-span-5 md:col-span-2'}>{translateCommon(
                  'BTN.SEARCH')}</ContainedButton>

            </div>
          </form>
          <div className={'text-red-600 text-sm h-8'}>
            {error}
          </div>
        </div> :
        <></>}

    <div className={'flex flex-col md:flex-row gap-5'}>

      <div className={`w-full ${mapHeight} `}>

        <GoogleMapReact
            bootstrapURLKeys={{key: GOOGLE_MAPS_KEY}}
            defaultCenter={options.center}
            defaultZoom={options.zoom}
            options={
              {
                styles: lightGoogleMapsStyles,
              }
            }
        >

          {workshops.map(workshop => {

            return <WorkshopMapPin key={`WORKSHOP_${workshop.id}`}
                                   lat={workshop.latitude}
                                   lng={workshop.longitude}
                                   text={workshop.title}
                                   partner={workshop}
                                   showLink={showPartnerLink}
                                   isCheckout={isCheckout}
                                   setPartner={setPartner}
                                   selectedPartnerId={selectedPartnerId}

            />;
          })}
        </GoogleMapReact>

      </div>

      {showNearestPartner &&
      <div className={`md:w-48 w-full`}>
        <h2 className={'text-tiny font-semibold tracking-wide uppercase'}>
          {translateCommon('COMPONENTS.MAP.DISTANCE')}
        </h2>

        <ul className={`flex flex-col gap-2 divide-solid divide-y overflow-y-scroll overflow-x-hidden ${mapHeight}`}>
          {workshops.map(workshop => {

            const {title, address, id} = workshop;
            const {street, postalCode, city} = address;

            const fullAddress = getAddress(street, postalCode, city);
            const key = `partner_${id}`;

            return <div key={key} className={'pr-2'}>
              <p className={'uppercase text-tiny text-gray-900 font-semibold mt-2'}> {fullAddress} </p>
              <h3 className={'text-sm text-gray-800 font-light'}>{title}</h3>
              {!!workshop.distance ?
                  <div>
                    <p className={'text-sm font-base underline'}> {workshop.distance.toFixed(
                        2)} km </p>

                  </div> :
                  <div className="animate-pulse flex space-x-4">
                    <div className="flex-1 space-y-3 py-1">
                      <div className="h-4 bg-gray-400 rounded-sm w-16"/>
                    </div>
                  </div>}
            </div>;

          })}
        </ul>
      </div>}
    </div>
  </>;
}
