import qs from 'qs';

export const geocodingOnPostalCode = async (postalCode, country, setError) => {
  const LOCATION_IQ_TOKEN = process.env.LOCATION_IQ_TOKEN;

  if (postalCode === null) {
    setError('Postalcode is empty');
    console.error('Postalcode is empty');
    return;
  }

  //TODO: Change token to secret file

  const query = {
    key: 'pk.0b62a914e7d5128804764b3c5f2198f5',
    format: 'json',
    q: `${postalCode}, ${country}`,
    // country: country.toLowerCase(),
  };

  console.log(query);

  const queryString = qs.stringify(query);
  const url = `https://eu1.locationiq.com/v1/search.php?${queryString}`;

  const res = await fetch(url);
  const data = await res.json();
  if (!data) {
    return;
  }

  setError('');
  return data;

};

export const computeDistance = async (start, end) => {

  const res = await fetch(
      `https://eu1.locationiq.com/v1/directions/driving/${start.lat},${start.lon};${end.lat},${end.lon}?key=${LOCATION_IQ_TOKEN}&overview=false&steps=false`);

  const data = await res.json();
  if (!data) {
    return;
  }

  return data;
};

export const computeAllDistances = async (
    places, partners, partnerDistances) => {

  if (places.length === 0) return;

  const start = {
    lat: places[0].lat,
    lon: places[0].lon,
  };

  const _partners = [...partners];
  const partnerArrays = [];

  for (let i = 0; i < _partners.length; i += 10) {

    partnerArrays.push(_partners.slice(i, i + 10));

  }

  for (let i = 0; i < partnerArrays.length; i++) {

    partnerArrays[i].map(partner => {

      const end = {
        lat: partner.latitude,
        lon: partner.longitude,
      };

      setTimeout(async () => {
        let distance = await computeDistance(start, end);
        const _partner = {
          ...partner,
          ...distance,
        };

        partnerDistances.push(_partner);
      }, 1000 * i);

    });

  }

};
