export const logoPlain = {
  url: 'https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/b9869c6234dba40cc33d8808a1f9b6c4.png',
  width: 829,
  height: 829,
  alternativeText: 'AMPTech Logo',
};

export const logoPlainBlackBg = {
  url: 'https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/8c028de2c9b576c0e64eb579c21315bf.png',
  width: 1000,
  height: 1000,
  alternativeText: 'AMPTech Logo',
};